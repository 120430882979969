<template lang="html">
  <div class="auth">
    <div class="auth-logo-container">
      <img class="logo" src="/logo.png" width="300">
    </div>

    <div class="auth-modal">
      <transition name="message" mode="out-in">
        <div class="auth-modal__error" v-if="error">{{ error }}</div>
      </transition>
      <transition name="message" mode="out-in">
        <div class="auth-modal__success" v-if="success">{{ success }}</div>
      </transition>


      <transition name="message" mode="out-in">
        <h1 class="auth-modal__title" v-if="this.currentMode.name === 'Login' && !this.resetPasswordCode">Login</h1>
      </transition>
      <transition name="message" mode="out-in">
        <h1 class="auth-modal__title" v-if="this.currentMode.name === 'Registration' && !this.resetPasswordCode">Registration</h1>
      </transition>
      <h1 class="auth-modal__title" v-if="this.resetPasswordCode">Password reset</h1>

      <transition name="form-change" mode="out-in">
        <form 
          class="auth-modal__form"
          v-if="currentMode.name === 'Registration' && !this.resetPasswordCode"
          @submit.prevent="onRegistration"
        >
          <div class="input-container">
            <label class="input-title">{{ currentMode.switcher.question }}</label>
            <button
              class="input-button"
              type="button"
              @click="onSwitchMode"
            >{{ currentMode.switcher.action }}</button>
          </div>
          <div class="input-container">
            <label class="input-title" for="username">Username:</label>
            <input
              class="input-text"
              type="text"
              id="username"
              v-model="regUsername"
              placeholder="Enter username"
              name="username"
            >
          </div>
          <div class="input-container">
            <label class="input-title" for="email">Email:</label>
            <input
              class="input-text"
              type="email"
              id="email"
              v-model="regEmail"
              placeholder="Enter email"
              name="email"
            >
          </div>
          <div class="input-container">
            <label class="input-title" for="password">Password:</label>
            <input
              class="input-text"
              type="password"
              id="password"
              v-model="regPassword"
              placeholder="Enter password"
              name="password"
            >
          </div>
          <transition name="req-show">   
            <div 
              class="input-container"
              v-show="regPassword.length"
            >
              <label class="input-title"></label>
              <ul class="password-reqlist">
                <li
                  class="password-reqitem"
                  :class="{'match': passwordMatch.digit}"
                >At least 1 digit</li>
                <li
                  class="password-reqitem"
                  :class="{'match': passwordMatch.letter}"
                >At least 1 letter (a-z or A-Z)</li>
                <li
                  class="password-reqitem"
                  :class="{'match': passwordMatch.symbols}"
                >At least 6 symbols</li>
              </ul>
            </div>
          </transition>
          <div class="input-container">
            <label class="input-title" for="password_repeat">Repeat password:</label>
            <input
              class="input-text"
              type="password"
              id="password_repeat"
              v-model="regPasswordRepeat"
              placeholder="Enter password again"
              name="re-password"
            >
          </div>
          <div class="buttons-container">
            <button
              class="input-button"
              type="submit"
            >{{ currentMode.action }}</button>
          </div>
        </form>
      </transition>

      <transition name="form-change" mode="out-in">
        <form 
          class="auth-modal__form"
          v-if="currentMode.name === 'Login' && !this.resetPasswordCode"
          @submit.prevent="onLogin"
        >
          <div class="input-container">
            <label class="input-title">{{ currentMode.switcher.question }}</label>
            <button
              class="input-button"
              type="button"
              @click="onSwitchMode"
            >{{ currentMode.switcher.action }}</button>
          </div>
          <div class="input-container">
            <label class="input-title" for="identifier">Email:</label>
            <input
              class="input-text"
              type="text"
              id="identifier"
              v-model="logEmail"
              placeholder="Enter email"
              name="email"
            >
          </div>
          <div class="input-container">
            <label class="input-title" for="password">Password:</label>
            <input
              class="input-text"
              type="password"
              id="password"
              v-model="logPassword"
              placeholder="Enter password"
              name="password"
            >
          </div>
          <div class="input-container checkbox">
            <label class="input-title" for="keep-logged">Keep me logged:</label>
            <input
              class="input-checkbox"
              type="checkbox"
              v-model="logKeepLogged"
            >
            <button 
              class="input-button forgot"
              type="button"
              @click="onForgotPassword"
              v-if="!loader"
            >Forgot password?</button>
          </div>
          <div class="loader-container" v-if="loader">
            <img :src="require('../assets/img/autosave_saving.gif')">
          </div>
          <div class="buttons-container" v-if="!loader">
            <button
              class="input-button"
              type="submit"
            >{{ currentMode.action }}</button>
          </div>
        </form>
      </transition>

      <form 
        class="auth-modal__form auth-modal__form_password-reset"
        v-if="this.resetPasswordCode"
        @submit.prevent="onResetPassword"
      >
        <div class="input-container">
          <label class="input-title" for="new_password">New password: </label>
          <input 
            class="input-text"
            type="password"
            v-model="regPassword"
            id="new_password"
            placeholder="Enter new password"
          >
        </div>
        <transition name="req-show">   
          <div 
            class="input-container"
            v-show="regPassword.length"
          >
            <label class="input-title"></label>
            <ul class="password-reqlist">
              <li
                class="password-reqitem"
                :class="{'match': passwordMatch.digit}"
              >At least 1 digit</li>
              <li
                class="password-reqitem"
                :class="{'match': passwordMatch.letter}"
              >At least 1 letter (a-z or A-Z)</li>
              <li
                class="password-reqitem"
                :class="{'match': passwordMatch.symbols}"
              >At least 6 symbols</li>
            </ul>
          </div>
        </transition>
        <div class="input-container">
          <label class="input-title" for="repeat_password">Repeat password: </label>
          <input 
            class="input-text"
            type="password"
            v-model="regPasswordRepeat"
            id="repeat_password"
            placeholder="Enter new password again"
          >
        </div>
        <div class="loader-container" v-if="loader">
          <img :src="require('../assets/img/autosave_saving.gif')">
        </div>
        <div class="buttons-container" v-if="!loader">
          <button
            class="input-button"
            type="submit"
          >Reset</button>
        </div>
      </form>

      <img class="logo" src="/logo.png" width="100">
      <!-- End of auth-modal -->
    </div>
    <!-- End of auth -->
  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js"></script>

<script>
import ModalMessageMixin from '@/mixins/ModalMessageMixin';

export default {
  name: 'Auth',
  mixins: [ModalMessageMixin],
  data: () => ({
    loader: false,

    modeRegistration: {
      name: 'Registration',
      action: 'Register',
      switcher: {
        question: 'Already have an account? ',
        action: 'Login'
      }
    },
    modeLogin: {
      name: 'Login',
      action: 'Login',
      switcher: {
        question: 'You do not have an account? ',
        action: 'Register'
      }
    },
    currentMode: '',

    regUsername: '',
    regEmail: '',
    regPassword: '',
    regPasswordRepeat: '',

    passwordMatch: {
      digit: false,
      letter: false,
      symbols: false
    },

    logEmail: '',
    logPassword: '',
    logKeepLogged: false,

    resetPasswordCode: '',

    allowForgotPassword: true
  }),
  created() {
    this.currentMode = this.modeLogin;

    if (this.$route.query.code) {
      this.resetPasswordCode = this.$route.query.code;
    } 
  },
  methods: {
    onSwitchMode() {
      if (this.currentMode.name === 'Registration') {
        this.currentMode = this.modeLogin;
      } else if (this.currentMode.name === 'Login') {
        this.currentMode = this.modeRegistration;
      }
      this.error = '';
    },
    async onRegistration() {
      if (this.regUsername.length < 3) {
        this.setMessage('error', 'Username must contain at least 3 symbols');
        return;
      } else if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.regEmail))) {
        this.setMessage('error', 'Invalid email format');
        return;
      } else if (!this.passwordMatch.digit || !this.passwordMatch.letter || !this.passwordMatch.symbols) {
        this.setMessage('error', 'Password does not match the requirements');
        return;
      } else if (this.regPassword !== this.regPasswordRepeat) {
        this.setMessage('error', 'Passwords do not match');
        return;
      }
      try {
        //  username and email are default strapi auth fields which contain unique values  
        const regData = {
          login: this.regUsername,
          email: this.regEmail,
          username: this.regEmail,
          password: this.regPassword
        };
        await this.$store.dispatch('registerUser', regData);
        await this.$store.dispatch('createTeam', { name: regData.login })
        this.$router.push('/');
      } catch (e) {
        const errorMessage = e.response.data.message[0].messages[0].message;
        this.setMessage('error', errorMessage);
      }
    },
    async onLogin() {
      if (!this.logEmail.length) {
        this.setMessage('error', 'Enter email');
        return;
      } else if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.logEmail))) {
        this.setMessage('error', 'Invalid email format');
        return;
      } else if (!this.logPassword.length) {
        this.setMessage('error', 'Enter password');
        return;
      }
      try {
        const logData = {
          identifier: this.logEmail,
          password: this.logPassword
        };
        await this.$store.dispatch('loginUser', { logData, keepLogged: this.logKeepLogged });
        this.$router.push('/');
      } catch (e) {
        const errorMessage = e.response.data.message[0].messages[0].message;
        this.setMessage('error', errorMessage); 
        this.$store.commit('updateToken', '');
        this.$store.commit('updateUser', '');
      }
    },
    async onForgotPassword() {
      try {
        if (!this.allowForgotPassword) throw 'You can reset password once per minute';
        this.loader = true;
        const email = this.logEmail;
        if (!email) throw 'Enter email';
        await this.$store.dispatch('forgotPassword', email);
        this.setMessage('success', 'Password reset link was sent to email: ' + email);
        this.allowForgotPassword = false;
        setTimeout(() => {
          this.allowForgotPassword = true;
        }, 60000);
      } catch (e) {
        this.setMessage('error', e);
      } finally {
        this.loader = false;
      }
    },
    async onResetPassword() {
      try {
        if (!this.regPassword) {
          throw 'Enter new password';
        } else if (!this.regPasswordRepeat) {
          throw 'Repeat new password';
        } else if (!this.passwordMatch.digit || !this.passwordMatch.letter || !this.passwordMatch.symbols) {
          throw 'Password does not match the requirements';
        } else if (this.regPassword !== this.regPasswordRepeat) {
          throw 'Passwords do not match';
        }
        await this.$store.dispatch('resetPassword', {
          code: this.resetPasswordCode,
          password: this.regPassword,
          passwordConfirmation: this.regPasswordRepeat
        });
        this.$router.push('/');
        location.reload();
      } catch (e) {
        this.setMessage('error', e);
      }
    }
  },
  watch: {
    regPassword: {
      handler(value) {
        this.passwordMatch.digit = /\d/.test(value);
        this.passwordMatch.letter = /[a-zA-Z]/.test(value);
        this.passwordMatch.symbols = value.length >= 6;  
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/mixins/mixins'

.auth
  width: 100%
  height: 100vh
  overflow: hidden
  display: flex
 
  &-logo-container
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    background-color: #f6f6f6
    .logo
      width: 300px
      z-index: 2

  &-modal
    @include modal
    width: 480px
    padding-top: 100px
    box-shadow: -4px 0px 10px -6px #000
    .password
      &-reqlist
        list-style: none
      &-reqitem
        position: relative
        &::before
          content: ''
          display: block
          height: 3px
          width: 3px
          border-radius: 100%
          background-color: $pink
          position: absolute
          top: 50%
          transform: translateY(-50%)
          left: -12px
          transition: .1s linear
        &.match
          &::before
            background-color: limegreen
            border-radius: 0
            height: 10px
            width: 2px
            transform: rotate(35deg) translateY(-7px)
          &::after
            content: ''
            display: block
            width: 2px
            height: 5px
            background-color: limegreen
            position: absolute
            top: 50%
            left: -10px
            transform: rotate(-35deg) translateY(-2px)
        &:not(:last-child)
          margin-bottom: 10px
    .logo
      display: none
    &__form 
      .input-container 
        .input-button
          &.forgot
            font-size: 14px
            text-transform: none
            margin-left: auto
      .loader-container
        img
          margin-left: auto
      &_password-reset
        .input-container
          &:first-child 
            margin-bottom: 20px 

.req-show-enter, .req-show-leave-to
  opacity: 0
  transform: rotateX(100deg) scale(0.1)

.req-show-enter-active, .req-show-leave-active
  transition: all .2s ease   

.form-change-enter, .form-change-leave-to
  opacity: 0

.form-change-enter-active, .form-change-leave-active
  transition: all .2s ease   

@media screen and (max-width: 960px) 
  .auth 
    overflow-y: scroll
    &-logo-container
      display: none
    &-modal
      width: 100%
      box-shadow: none
      position: relative
      .logo
        display: block
        position: absolute
        top: 30px
        left: 20px
      &__error
        width: 100%
      &__success
        width: 100%

@media screen and (max-width: 420px)
  .auth
    &-modal
      padding-top: 50px
      &__form
        width: 100%
        padding: 0 40px
        padding-bottom: 150px
        .input-container
          width: 100%
          flex-direction: column
          margin-bottom: 30px !important 
          .input-title
            width: 100%
            text-align: left
            margin-bottom: 5px
            padding: 0
          .input-text
            width: 100%
            border-radius: 0
          &:first-child
            .input-title
              text-align: center
              margin-bottom: 10px
          &.checkbox
            align-items: flex-start
            .input-title
              margin-bottom: 10px
        .buttons-container
          justify-content: center
          margin-top: 10px
        .input-button
          font-size: 17px 

@media screen and (max-width: 350px)
  .auth
    &-modal
      .logo
        display: none
</style>
